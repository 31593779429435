import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    padding: 5%;
  }

  body {
    margin: 0;
    font-family: 'Oatmeal', sans-serif;
    font-feature-settings: 'ss01' on;
    color: black;
  }

  /*
  * Turn off an 'ss01' feature for the titles,
  * which corresponds to QuintoAndar Titles.
  */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-feature-settings: 'ss01' off;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  :root {
    --primary: #317C8B;
  }

  .header {
    margin-top: 10%;
  }
`;
