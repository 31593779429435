/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import '@quintoandar/cozy-typeface/index.css';

import { Home } from './pages/Home/Loadable';
import { CallStatus } from './pages/CallStatus/Loadable';
import { FillName } from './pages/FillName/Loadable';
import { Example } from './pages/Example/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { SelectGrouping } from './pages/SelectGrouping/Loadable';
import { SelectDeliveryCompany } from './pages/SelectDeliveryCompany/Loadable';
import { SelectUnit } from './pages/SelectUnit/Loadable';
import { Selfie } from './pages/Selfie/Loadable';
import { RequestPermissions } from './pages/RequestPermissions/Loadable';
import { WaitingResident } from './pages/WaitingResident/Loadable';

// Loading
import { LoadingProvider } from 'context/LoadingContext';

export function App() {
  return (
    <BrowserRouter>
      <LoadingProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/status" element={<CallStatus />} />
          <Route path="/select-grouping" element={<SelectGrouping />} />
          <Route path="/select-grouping/select-unit" element={<SelectUnit />} />
          <Route
            path="/select-grouping/select-unit/fill-name"
            element={<FillName />}
          />
          <Route
            path="/select-grouping/select-unit/fill-name/selfie"
            element={<Selfie />}
          />
          <Route
            path="/select-delivery-company"
            element={<SelectDeliveryCompany />}
          />
          <Route path="/request-permissions" element={<RequestPermissions />} />
          <Route path="/waiting-resident" element={<WaitingResident />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/example" element={<Example />} />
        </Routes>
      </LoadingProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
}
