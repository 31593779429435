/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

// Import root app
import { App } from 'app';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import { IntlProvider } from 'react-intl';

// Import Cozy
import { Theme, ThemeWrapper } from '@quintoandar/cozy-core/theming';
import '@quintoandar/cozy-theme/cozy-theme/v6/latest/defaultNext/defaultNext.css';

const store = configureAppStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <ThemeWrapper name={Theme.DEFAULT_NEXT}>
      {/* TODO: configurar Intl */}
      <IntlProvider locale="pt-BR">
        <App />
      </IntlProvider>
    </ThemeWrapper>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
