/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const SelectGrouping = lazyLoad(
  () => import('./index'),
  module => module.SelectGrouping,
);
